<template>
    <div class="positionT0L0">
        <div class="app-form">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" labelAlign="right" :label-col="labelCol" :wrapper-col="wrapperCol">
                <a-form-model-item ref="title" label="视频名称" prop="title">
                    <a-input v-model="form.title" placeholder="请输入视频名称" style="width: 420px">
                        <div slot="addonAfter">{{ form.title.length }}/45</div>
                    </a-input>
                </a-form-model-item>

                <a-form-model-item ref="coverUrl" label="视频封面" prop="coverUrl">
                    <UpdataImg @avatarfns="imgUrlFn" :imageUrlNum="1" :imgUrl="coverUrlArr" :urlSize="500"></UpdataImg>
                    <span style="color: #f5222d" v-if="isImageAry">请上传视频封面</span>
                </a-form-model-item>

                <a-form-model-item ref="videoUrl" label="视频上传" prop="videoUrl">
                    <QiniuVideoAudio
                        :oldFileName="form.videoUrl"
                        :type="2"
                        id="videoUpdate"
                        @videoFn="videoFn"
                        @changeOldFileName="changeOldFileName"
                    />
                    <div style="font-weight: 600;line-height: 15px">格式支持mp4，为保证音频加载与播放的流畅性，<br>建议上传大小不超过100M。</div>
                    <span style="color: #f5222d" v-if="isVideoUrl">请上传视频</span>
                </a-form-model-item>
            </a-form-model>
        </div>

        <footer-tool-bar :collapsed="sideCollapsed">
            <a-button class="margin_right60" @click="$router.go(-1)">返回</a-button>
            <a-button type="primary" @click="keepClick" :loading="isDisabled">保存</a-button>
        </footer-tool-bar>
    </div>
</template>

<script>
import FooterToolBar from "@/components/FooterToolbar";
import { baseMixin } from "@/store/app-mixin";
import {AddVideoForProgram} from "@/request/api/appletsManage";
import UpdataImg from "@/components/file/updataImg";
import QiniuVideoAudio from "@/components/file/QiniuVideoAudio.vue";

export default {
    mixins: [baseMixin],
    components: {
        FooterToolBar,
        UpdataImg,
        QiniuVideoAudio
    },
    data() {
        return {
            labelCol: { span: 2 },
            wrapperCol: { span: 12 },
            isDisabled: false,
            isImageAry:false,
            isVideoUrl:false,
            coverUrlArr:[],
            form: {
                title:'',
                coverUrl:'',
                videoUrl:'',
            },
            rules: {
                title: [
                    { required: true, message: "请输入视频名称", trigger: "blur" },
                    { max: 45, message: "最多输入45个字符", trigger: "change" },
                ],
                coverUrl: [{ required: true, message: "",}],
                videoUrl: [{ required: true, message: "",}],
            },
        };
    },
    created() {

    },
    methods: {
        // 上传回调
        imgUrlFn(data) {
            this.form.coverUrl = data
        },

        // 视频函数
        videoFn(obj) {
            this.form.videoUrl = obj.fileUrl;
        },

        // 改变视频文件名
        changeOldFileName(oldFileName) {},

        keepClick() {
            const that = this;

            if(this.form.coverUrl.length>0){
                this.isImageAry = false
            }else{
                this.isImageAry = true
                return false
            }

            if(this.form.videoUrl){
                this.isVideoUrl = false
            }else{
                this.isVideoUrl = true
                return false
            }

            that.isDisabled = true;
            setTimeout(() => {
                that.isDisabled = false;
            }, 3000);

            that.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    this.form.coverUrl = this.coverUrlArr.toString()
                    AddVideoForProgram(that.form).then((res) => {
                        if (res.code == 200) {
                            that.$message.success("新增成功");
                            that.$router.push("/appletsManage/liveBroadcast");
                        }
                    });
                }
            });
        },
    },
    beforeDestroy() {
        sessionStorage.removeItem("formData");
    },
};
</script>

<style lang="less" scoped>
.date-picker-label {
    margin-left: 10px;
    margin-right: 10px;
}
.product-box {
    position: relative;
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    .product-close {
        position: absolute;
        top: -10px;
        right: -10px;
        cursor: pointer;
    }
    .product-wrap {
        height: 86px;
        &.bg {
            background: #ebebeb;
        }
        .left-wrap {
            .product-img {
                margin-right: 10px;
                height: 86px;
                .p-img {
                    height: 86px;
                    border-top-left-radius: 4px;
                    border-bottom-left-radius: 4px;
                }
            }
            .product-info {
                flex-direction: column;
                height: 86px;
                .p-title {
                    margin-top: 6px;
                    font-size: 14px;
                    color: #000;
                }
                .p-price {
                    color: red;
                }
                .p-type {
                    color: #9fa5af;
                    font-size: 12px;
                    line-height: 18px;
                }
            }
        }
        .right-wrap {
            margin-left: 24px;
            margin-right: 24px;
        }
    }
}
</style>
